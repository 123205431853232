import React from "react";

const GameBoard = ({ boardSize, board, handleClick }) => {
    const renderSquare = (index) => {
        return (
            <div key={index} className="board__tile">
                <button className="board__tile-button" onClick={() => handleClick(index)}>
                    {board[index] === "X" && <i className="icon-xmark"></i>}
                    {board[index] === "O" && <i className="icon-zero"></i>}
                </button>
            </div>
        );
    };

    const renderBoard = () => {
        let rows = [];
        for (let i = 0; i < boardSize; i++) {
            let squares = [];
            for (let j = 0; j < boardSize; j++) {
                squares.push(renderSquare(i * boardSize + j));
            }
            rows.push(squares);
        }
        return rows.flat();
    };

    return <div className="board__wrapper">{renderBoard()}</div>;
};

export default GameBoard;
