// src/Utils/Boards/generateBoardId.js
import { ref, get } from "firebase/database";
import { database } from "../../../Utils/Firebase/config";

const generateRandomId = () => {
    const number = Math.floor(1000 + Math.random() * 9000);
    const letter = String.fromCharCode(65 + Math.floor(Math.random() * 6)); // Генерация случайной буквы от A до F
    return `${number}${letter}`;
};

const checkBoardIdExists = async (boardId) => {
    const boardRef = ref(database, `boards/${boardId}`);
    const snapshot = await get(boardRef);
    return snapshot.exists();
};

export const generateBoardId = async () => {
    let boardId;
    let exists = true;

    while (exists) {
        boardId = generateRandomId();
        exists = await checkBoardIdExists(boardId);
    }

    return boardId;
};
