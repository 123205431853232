// src/Utils/Firebase/dataUpdate.js
import { ref, update, onValue } from "firebase/database";
import { database } from "./config";

// Функция для получения данных из Firebase
export const fetchBoardData = (boardId, setBoard) => {
    const boardRef = ref(database, `boards/${boardId}`);
    onValue(boardRef, (snapshot) => {
        const data = snapshot.val();
        console.log("Data from Firebase:", data);
        setBoard(data);
    });
};

// Функция для обновления данных в Firebase
export const updateFirebaseData = (path, data) => {
    return update(ref(database, path), data);
};
