// src/config/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyBzgAYfhmj6kKH4D3OShuGxXv7V5Ozth8Q",
    authDomain: "xogames-b3934.firebaseapp.com",
    databaseURL: "https://xogames-b3934-default-rtdb.firebaseio.com",
    projectId: "xogames-b3934",
    storageBucket: "xogames-b3934.appspot.com",
    messagingSenderId: "986038030497",
    appId: "1:986038030497:web:fed2c7826e3a07ad26e0e0",
    measurementId: "G-J8EFT05BR9",
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const analytics = getAnalytics(app);

export { database, analytics };
