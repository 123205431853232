import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Container from "../../Components/Container";
import GameBoard from "../../Components/GameBoard";

import usePageColor from "../../Hooks/usePageColor";

const PrivateBoard = () => {
    console.log("PrivateBoard component rendered");
    usePageColor("--c-red");

    // Считываем параметры из localStorage
    const settings = JSON.parse(localStorage.getItem("ticTacToeSettings"));
    const { size, gameMode, players, gameType } = settings;

    console.log({ size, gameMode, players, gameType });

    const boardSize = parseInt(size);
    const initialBoard = Array(boardSize * boardSize).fill(null);

    const [board, setBoard] = useState(() => {
        console.log("Initial board state set");
        return initialBoard;
    });
    const [isXNext, setIsXNext] = useState(true);
    const [history, setHistory] = useState([]);

    useEffect(() => {
        console.log("useEffect triggered");
        setBoard(Array(boardSize * boardSize).fill(null));
        setIsXNext(true);
        setHistory([]);
    }, [boardSize]);

    const handleClick = (index) => {
        console.log(`Clicked on square ${index}`);
        if (board[index] || calculateWinner(board, boardSize)) {
            console.log(`Square ${index} already taken or game won`);
            return;
        }
        const newBoard = board.slice();
        newBoard[index] = isXNext ? "X" : "O";
        setBoard(newBoard);
        setIsXNext(!isXNext);
        setHistory([...history, newBoard]);
        console.log(`New board state: ${newBoard}`);
    };

    const calculateWinner = (squares, size) => {
        // Check rows
        for (let row = 0; row < size; row++) {
            for (let col = 0; col <= size - 3; col++) {
                const idx = row * size + col;
                if (squares[idx] && squares[idx] === squares[idx + 1] && squares[idx] === squares[idx + 2]) {
                    return squares[idx];
                }
            }
        }

        // Check columns
        for (let col = 0; col < size; col++) {
            for (let row = 0; row <= size - 3; row++) {
                const idx = row * size + col;
                if (squares[idx] && squares[idx] === squares[idx + size] && squares[idx] === squares[idx + 2 * size]) {
                    return squares[idx];
                }
            }
        }

        // Check diagonals
        for (let row = 0; row <= size - 3; row++) {
            for (let col = 0; col <= size - 3; col++) {
                const idx = row * size + col;
                if (squares[idx] && squares[idx] === squares[idx + size + 1] && squares[idx] === squares[idx + 2 * size + 2]) {
                    return squares[idx];
                }
            }
            for (let col = 2; col < size; col++) {
                const idx = row * size + col;
                if (squares[idx] && squares[idx] === squares[idx + size - 1] && squares[idx] === squares[idx + 2 * size - 2]) {
                    return squares[idx];
                }
            }
        }
        return null;
    };

    // const winner = calculateWinner(board, boardSize);
    const currentPlayer = isXNext ? "X" : "O";

    return (
        <Container>
            <div className="board" data-size={size}>
                <div className="board__headline">
                    <Link to="/">
                        <i className="icon-arrow-left"></i>
                    </Link>
                    <h1>Private board</h1>
                </div>
                <ul className="board__players">
                    <li>
                        <strong>22</strong>
                        <span>CMDFEM</span>
                        <i className="icon-xmark"></i>
                    </li>
                    <li>
                        <strong>12</strong>
                        <span>AI BOT</span>
                        <i className="icon-zero"></i>
                    </li>
                </ul>
                <div className="board__status">
                    <strong>Turn: </strong>
                    <span>CMDFEM</span>
                    <i className={`icon-${currentPlayer === "X" ? "xmark" : "zero"}`}></i>
                </div>
                <GameBoard boardSize={boardSize} board={board} handleClick={handleClick} />
            </div>
        </Container>
    );
};

export default PrivateBoard;
