// src/Utils/Firebase/initializeBoard.js

// Функция для инициализации игрового поля
export const initializeBoard = (boardSize) => {
    const initialBoard = {};
    for (let i = 1; i <= boardSize * boardSize; i++) {
        initialBoard[i] = "";
    }
    return initialBoard;
};

// Функция для создания объекта игры
export const boardObject = (boardId, userId, boardSize, numberOfPlayers, mode) => {
    return {
        boardId: boardId,
        playersQuantity: numberOfPlayers,
        boardSize: boardSize,
        mode: mode,
        status: "waiting", // Игра начинается в состоянии ожидания
        createdAt: new Date().toISOString(),
        rounds: {
            count: 0,
            current: {
                turn: userId,
                steps: 0,
                board: initializeBoard(boardSize),
            },
        },
        players: {
            [userId]: {
                symbol: "xmark",
                name: "Player1",
                wins: 0,
                status: "online",
            },
        },
    };
};
