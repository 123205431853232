import React from "react";
import usePageColor from "../../Hooks/usePageColor";

const CreateBoard = () => {
    usePageColor("--c-orange"); // Используйте CSS-переменную для создания доски

    return <h1>Подключение к доске</h1>;
};

export default CreateBoard;
