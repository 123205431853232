import React from "react";

const BoardStatus = ({ status, turn, players, winner, startNewRound }) => (
    <div className="board__status">
        {status === "waiting" && <strong>Waiting for players...</strong>}
        {status === "active" && (
            <>
                <strong>Turn: </strong>
                <span>{players[turn]?.name}</span>
                <i className={`icon-${players[turn]?.symbol}`}></i>
            </>
        )}
        {status === "completed" && (
            <strong>
                GAME OVER: {winner === "Draw" ? "It's a draw!" : `${winner} wins!`}
                <button onClick={startNewRound} className="start-new-round-btn">
                    Start New Round
                </button>
            </strong>
        )}
    </div>
);

export default BoardStatus;
