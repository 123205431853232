import React from "react";
import RadioButton from "./RadioButton";

const RadioGroup = ({ title, name, options, selectedOption, onChange }) => {
    return (
        <div className="section-group">
            <h3 className="section-group__title">{title}</h3>
            <div className="section-group__content">
                {options.map((option) => (
                    <RadioButton key={option} name={name} id={`${name}-${option}`} checked={selectedOption === `${name}-${option}`} onChange={onChange} label={option} />
                ))}
            </div>
        </div>
    );
};

export default RadioGroup;
