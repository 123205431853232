import React from "react";
import { Link } from "react-router-dom";

import usePageColor from "../../Hooks/usePageColor";

import Container from "../../Components/Container";

const CreateBoard = () => {
    usePageColor("--c-orange"); // Используйте CSS-переменную для создания доски

    return (
        <Container>
            <div className="board" data-size="3">
                <div className="board__headline">
                    <Link to="/">
                        <i className="icon-arrow-left"></i>
                    </Link>
                    <h1>7326F board</h1>
                </div>
                <ul className="board__players">
                    <li>
                        <strong>22</strong>
                        <span>CMDFEM</span>
                        <i className="icon-xmark"></i>
                    </li>
                    <li>
                        <strong>12</strong>
                        <span>AI BOT</span>
                        <i className="icon-zero"></i>
                    </li>
                </ul>
                <div className="board__status">
                    <strong>Turn: </strong>
                    <span>CMDFEM</span>
                    <i className="icon-xmark"></i>
                </div>
                <div className="board__wrapper">
                    <div className="board__tile">
                        <button className="board__tile-button"></button>
                    </div>
                    <div className="board__tile">
                        <button className="board__tile-button"></button>
                    </div>
                    <div className="board__tile">
                        <button className="board__tile-button"></button>
                    </div>
                    <div className="board__tile">
                        <button className="board__tile-button">
                            <i className="icon-zero"></i>
                        </button>
                    </div>
                    <div className="board__tile">
                        <button className="board__tile-button">
                            <i className="icon-xmark"></i>
                        </button>
                    </div>
                    <div className="board__tile">
                        <button className="board__tile-button"></button>
                    </div>
                    <div className="board__tile">
                        <button className="board__tile-button"></button>
                    </div>
                    <div className="board__tile">
                        <button className="board__tile-button"></button>
                    </div>
                    <div className="board__tile">
                        <button className="board__tile-button"></button>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default CreateBoard;
