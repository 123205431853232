import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import usePageColor from "../../Hooks/usePageColor";
import Container from "../../Components/Container";

const CreatePrivateBoard = () => {
    usePageColor("--c-red");

    const [gameMode, setGameMode] = useState("friend");
    const [players, setPlayers] = useState(2);
    const [size, setSize] = useState(3);
    const [gameType, setGameType] = useState("normal");
    const navigate = useNavigate();

    const handleGameModeChange = (event) => {
        const mode = event.target.value;
        setGameMode(mode);
        if (mode === "friend") {
            setPlayers(2);
        }
    };

    const handlePlayersChange = (event) => {
        setPlayers(parseInt(event.target.value));
    };

    const handleSizeChange = (event) => {
        setSize(parseInt(event.target.value));
    };

    const handleGameTypeChange = (event) => {
        setGameType(event.target.value);
    };

    const startGame = () => {
        const gameSettings = {
            gameMode,
            players,
            size,
            gameType,
        };
        localStorage.setItem("ticTacToeSettings", JSON.stringify(gameSettings));
        navigate(`/board/private/`);
    };

    return (
        <Container>
            <h1>Настройки игры</h1>
            <div>
                <label>Игра с:</label>
                <div>
                    <label>
                        <input type="radio" value="friend" checked={gameMode === "friend"} onChange={handleGameModeChange} />
                        Другом
                    </label>
                    <label>
                        <input type="radio" value="computer" checked={gameMode === "computer"} onChange={handleGameModeChange} />
                        Компьютером
                    </label>
                </div>
            </div>
            <div>
                <label>Количество игроков:</label>
                <div>
                    {[2, 3, 4, 5].map((num) => (
                        <label key={num}>
                            <input type="radio" value={num} checked={players === num} onChange={handlePlayersChange} disabled={gameMode === "friend" && num !== 2} />
                            {num}
                        </label>
                    ))}
                </div>
            </div>
            <div>
                <label>Размер поля:</label>
                <div>
                    {[3, 4, 5].map((sizeOption) => (
                        <label key={sizeOption}>
                            <input type="radio" value={sizeOption} checked={size === sizeOption} onChange={handleSizeChange} />
                            {sizeOption}x{sizeOption}
                        </label>
                    ))}
                </div>
            </div>
            <div>
                <label>Режим игры:</label>
                <div>
                    <label>
                        <input type="radio" value="normal" checked={gameType === "normal"} onChange={handleGameTypeChange} />
                        Обычный
                    </label>
                    <label>
                        <input type="radio" value="endless" checked={gameType === "endless"} onChange={handleGameTypeChange} />
                        Бесконечный
                    </label>
                </div>
            </div>
            <button onClick={startGame}>Начать игру</button>
        </Container>
    );
};

export default CreatePrivateBoard;
