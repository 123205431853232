// src/Screens/Boards/Func/startNewRound.js
import { updateFirebaseData } from "../../../Utils/Firebase/dataUpdate";
import { boardObject } from "./initializeBoard";

const startNewRound = (boardId, userId, boardSize, numberOfPlayers = 2, mode = "classic", setWinner, setGameCompleted) => {
    const gameObject = boardObject(boardId, userId, boardSize, numberOfPlayers, mode);
    updateFirebaseData(`boards/${boardId}`, gameObject).then(() => {
        if (setWinner) setWinner(null);
        if (setGameCompleted) setGameCompleted(false);
    });
};

export default startNewRound;
