import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Screens/Home/HomeScreen";
import CreateBoard from "./Screens/Boards/CreateBoard";
import CreatePrivateBoard from "./Screens/Boards/CreatePrivateBoard";
import JoinBoard from "./Screens/Boards/JoinBoard";
// import BoardInception from "./Screens/Boards/BoardInception";
import PrivateBoard from "./Screens/Boards/PrivateBoard";
import BoardTest from "./Screens/Boards/BoardTest";
import BoardTest2 from "./Screens/Boards/BoardTest2";
import Error404 from "./Screens/Other/Error404";

import Board from "./Screens/Boards/Board";

import { UserInit } from "./Utils/Users/UserInit";

const App = () => {
    useEffect(() => {
        UserInit();
    }, []);

    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/create/" element={<CreateBoard />} />
                <Route path="/create-private/" element={<CreatePrivateBoard />} />
                <Route path="/join/" element={<JoinBoard />} />
                <Route path="/test/" element={<BoardTest />} />
                <Route path="/test2/" element={<BoardTest2 />} />
                <Route path="/board/:boardId" element={<Board />} />
                <Route path="/board/private/" element={<PrivateBoard />} />
                <Route path="*" element={<Error404 />} />
            </Routes>
        </Router>
    );
};

export default App;
