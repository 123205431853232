import React from "react";
import { Link } from "react-router-dom";

const BoardHeader = ({ boardId }) => (
    <div className="board__headline">
        <Link to="/">
            <i className="icon-arrow-left"></i>
        </Link>
        <h1>{boardId} board</h1>
    </div>
);

export default BoardHeader;
