import { generateUUID } from "./uuid";

const USER_ID_KEY = "user_id";

export const UserInit = () => {
    if (!localStorage.getItem(USER_ID_KEY)) {
        const userId = generateUUID();
        localStorage.setItem(USER_ID_KEY, userId);
    }
};

export const getUserId = () => {
    return localStorage.getItem(USER_ID_KEY);
};
