// src/Screens/Boards/CreateBoard.jsx
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import usePageColor from "../../Hooks/usePageColor";
import Container from "../../Components/Container";
import RadioGroup from "../../Components/Form/RadioGroup";
import { generateBoardId } from "./Func/generateBoardId";
import startNewRound from "./Func/startNewRound";

const CreateBoard = () => {
    usePageColor("--c-purple");

    const [selectedNumberOfPlayers, setSelectedNumberOfPlayers] = useState("number-of-players-2");
    const [selectedBoardSize, setSelectedBoardSize] = useState("board-size-3");
    const [selectedMode, setSelectedMode] = useState("mode-classic");

    const navigate = useNavigate();

    const handleNumberOfPlayersChange = (event) => {
        setSelectedNumberOfPlayers(event.target.id);
    };

    const handleBoardSizeChange = (event) => {
        setSelectedBoardSize(event.target.id);
    };

    const handleModeChange = (event) => {
        setSelectedMode(event.target.id);
    };

    const handleCreateBoard = async () => {
        const boardId = await generateBoardId();
        const userId = localStorage.getItem("user_id");
        const boardSize = parseInt(selectedBoardSize.split("-").pop());
        const numberOfPlayers = parseInt(selectedNumberOfPlayers.split("-").pop());
        const mode = selectedMode.split("-").pop();

        startNewRound(
            boardId,
            userId,
            boardSize,
            numberOfPlayers,
            mode,
            () => {},
            () => {}
        );
        navigate(`/board/${boardId}`);
    };

    const radioGroups = [
        {
            title: "Number of players",
            name: "number-of-players",
            options: ["2", "3", "4", "5"],
            selectedOption: selectedNumberOfPlayers,
            onChange: handleNumberOfPlayersChange,
        },
        {
            title: "Board size",
            name: "board-size",
            options: ["3", "4", "5"],
            selectedOption: selectedBoardSize,
            onChange: handleBoardSizeChange,
        },
        {
            title: "Mode",
            name: "mode",
            options: ["classic", "infinity"],
            selectedOption: selectedMode,
            onChange: handleModeChange,
        },
    ];

    return (
        <Container>
            <div className="game-screen">
                <div className="game-screen__headline">
                    <Link to="/">
                        <i className="icon-arrow-left"></i>
                    </Link>
                    <h1>Create Board</h1>
                </div>
                <div className="sections-wrapper">
                    <div className="section">
                        {radioGroups.map((group) => (
                            <RadioGroup key={group.name} title={group.title} name={group.name} options={group.options} selectedOption={group.selectedOption} onChange={group.onChange} />
                        ))}
                        <button className="btn" onClick={handleCreateBoard}>
                            Create board
                        </button>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit quidem aspernatur odio corporis aliquid iusto.</p>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default CreateBoard;
