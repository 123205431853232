import React from "react";
import usePageColor from "../../Hooks/usePageColor";

const Error404 = () => {
    usePageColor("--c-blue"); // Используйте CSS-переменную для страницы ошибки

    return <h1>Ошибка 404: Страница не найдена</h1>;
};

export default Error404;
