import React from "react";

const BoardPlayers = ({ players }) => (
    <ul className="board__players">
        {Object.keys(players).map((playerId) => (
            <li key={playerId}>
                <strong>{players[playerId].wins}</strong>
                <span>{players[playerId].name}</span>
                <i className={`icon-${players[playerId].symbol}`}></i>
            </li>
        ))}
    </ul>
);

export default BoardPlayers;
