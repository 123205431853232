import React from "react";
import { Link } from "react-router-dom";
import usePageColor from "../../Hooks/usePageColor";
import Container from "../../Components/Container";
import Logotype from "../../images/xo-logotype.svg";
// import TicTacToe from "../../Components/TicTacToe";

const Home = () => {
    usePageColor("--c-purple"); // Используйте CSS-переменную для создания доски

    return (
        <Container>
            <div className="home">
                <h1 className="home__brand">
                    <img src={Logotype} alt="Tic Tac Toe logotype. By joy byte" />
                    <span>XO / Tic Tac Toe. By joy byte</span>
                </h1>
                <div className="sections-wrapper">
                    <div className="section">
                        <div className="section-group">
                            <h3 className="section-group__title">Online</h3>
                            <div className="section-group__content">
                                <Link className="btn--with-icon" to="/create/">
                                    <i className="icon-square"></i>
                                    <span>Create board</span>
                                </Link>
                                <Link className="btn--with-icon" to="/join/">
                                    <i className="icon-square"></i>
                                    <span>Connect to board</span>
                                </Link>
                            </div>
                        </div>
                        <div className="section-group">
                            <h3 className="section-group__title">Offline</h3>
                            <div className="section-group__content">
                                <Link className="btn--with-icon" to="/create-private/">
                                    <i className="icon-square"></i>
                                    <span>On one device</span>
                                </Link>
                                <p>You can play with computer or with a friend on the same device.</p>
                            </div>
                        </div>
                    </div>
                    <div className="section">
                        <div className="section-group">
                            <h3 className="section-group__title">Settings & Rules</h3>
                            <div className="section-group__content">
                                <Link className="btn--with-icon" to="/create-private/">
                                    <i className="icon-square"></i>
                                    <span>Settings</span>
                                </Link>
                                <Link className="btn--with-icon" to="/create-private/">
                                    <i className="icon-square"></i>
                                    <span>Rules</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <TicTacToe /> */}
        </Container>
    );
};

export default Home;
