// src/Screens/Boards/Board.jsx
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import usePageColor from "../../Hooks/usePageColor";
import Container from "../../Components/Container";
import { fetchBoardData } from "../../Utils/Firebase/dataUpdate";
import startNewRound from "./Func/startNewRound";
import resetGameCompleted from "./Func/resetGameCompleted";
// Board Parts
import BoardHeader from "./BoardComponents/BoardHeader";
import BoardPlayers from "./BoardComponents/BoardPlayers";
import BoardStatus from "./BoardComponents/BoardStatus";
import BoardGrid from "./BoardComponents/BoardGrid";

const Board = () => {
    usePageColor("--c-orange");

    const { boardId } = useParams();
    const userId = localStorage.getItem("user_id");
    const [board, setBoard] = useState(null);
    const [winner, setWinner] = useState(null);
    const [gameCompleted, setGameCompleted] = useState(false);

    useEffect(() => {
        fetchBoardData(boardId, setBoard);
    }, [boardId]);

    useEffect(() => {
        resetGameCompleted(board, setGameCompleted);
    }, [board]);

    if (!board) {
        return <Container>Loading...</Container>;
    }

    if (!board.players) {
        return <Container>No players found</Container>;
    }

    if (!board.rounds || !board.rounds.current) {
        return (
            <Container>
                Incomplete board data
                <pre>{JSON.stringify(board, null, 2)}</pre>
            </Container>
        );
    }

    return (
        <Container>
            <div className="board" data-size={board.boardSize}>
                <BoardHeader boardId={boardId} />
                <BoardPlayers players={board.players} />
                <BoardStatus status={board.status} turn={board.rounds.current.turn} players={board.players} winner={winner} startNewRound={() => startNewRound(boardId, userId, board.boardSize, setWinner, setGameCompleted)} />
                <BoardGrid board={board} setWinner={setWinner} gameCompleted={gameCompleted} setGameCompleted={setGameCompleted} />
            </div>
        </Container>
    );
};

export default Board;
