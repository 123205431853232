import React from "react";

const RadioButton = ({ name, id, checked, onChange, label }) => {
    return (
        <>
            <input type="radio" name={name} id={id} checked={checked} onChange={onChange} />
            <label htmlFor={id}>{label}</label>
        </>
    );
};

export default RadioButton;
