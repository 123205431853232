import React, { useEffect, useCallback } from "react";
import { update, ref, onValue } from "firebase/database";
import { database } from "../../../Utils/Firebase/config";

const BoardGrid = ({ board, setWinner, gameCompleted, setGameCompleted }) => {
    const userId = localStorage.getItem("user_id");

    useEffect(() => {
        const boardRef = ref(database, `boards/${board.boardId}/players`);
        onValue(boardRef, (snapshot) => {
            const players = snapshot.val();
            if (players && !players[userId]) {
                const newPlayerId = Object.keys(players).length + 1;
                const newPlayerSymbol = newPlayerId === 1 ? "xmark" : "zero";
                const newPlayer = {
                    [userId]: {
                        symbol: newPlayerSymbol,
                        name: `Player${newPlayerId}`,
                        wins: 0,
                        status: "online",
                    },
                };
                update(ref(database, `boards/${board.boardId}/players`), newPlayer);

                if (Object.keys(players).length + 1 === board.playersQuantity) {
                    update(ref(database, `boards/${board.boardId}`), { status: "active" });
                }
            }
        });

        // Обработчик для синхронизации состояния доски
        const currentBoardRef = ref(database, `boards/${board.boardId}/rounds/current`);
        onValue(currentBoardRef, (snapshot) => {
            const currentBoardData = snapshot.val();
            if (currentBoardData) {
                board.rounds.current = currentBoardData;
                setGameCompleted(false);
            }
        });
    }, [board, userId, setGameCompleted]);

    const { boardId, players, boardSize, rounds } = board;
    const { current } = rounds;
    const { board: currentBoard, steps, turn } = current;

    const checkForWinner = useCallback(
        (currentBoard) => {
            const winningCombinations = [
                [1, 2, 3],
                [4, 5, 6],
                [7, 8, 9],
                [1, 4, 7],
                [2, 5, 8],
                [3, 6, 9],
                [1, 5, 9],
                [3, 5, 7],
            ];

            for (let combination of winningCombinations) {
                const [a, b, c] = combination;
                if (currentBoard[a] && currentBoard[a] === currentBoard[b] && currentBoard[a] === currentBoard[c]) {
                    if (!gameCompleted) {
                        const winningPlayer = Object.keys(players).find((id) => players[id].symbol === currentBoard[a]);
                        setWinner(players[winningPlayer].name);
                        setGameCompleted(true);
                        update(ref(database, `boards/${boardId}/players/${winningPlayer}`), { wins: players[winningPlayer].wins + 1 }).then(() => update(ref(database, `boards/${boardId}`), { status: "completed" }));
                    }
                    return;
                }
            }

            if (steps >= boardSize * boardSize && !gameCompleted) {
                setWinner("Draw");
                setGameCompleted(true);
                update(ref(database, `boards/${boardId}`), { status: "completed" });
            }
        },
        [boardId, players, boardSize, setWinner, steps, gameCompleted, setGameCompleted]
    );

    useEffect(() => {
        if (board.status === "active" && !gameCompleted) {
            checkForWinner(currentBoard);
        }
    }, [currentBoard, checkForWinner, board.status, gameCompleted]);

    const handleSquareClick = (index) => {
        if (board.status !== "active" || gameCompleted) return;

        if (currentBoard[index] || turn !== userId) return;

        const newBoard = { ...currentBoard };
        newBoard[index] = players[userId].symbol;

        let nextPlayer = Object.keys(players).find((id) => id !== userId);
        if (!nextPlayer) {
            console.error("No next player found, using current player as next player");
            nextPlayer = userId;
        }

        update(ref(database, `boards/${boardId}/rounds/current`), {
            board: newBoard,
            turn: nextPlayer,
            steps: steps + 1,
        }).then(() => {
            board.rounds.current.board = newBoard; // Обновляем локальное состояние
            checkForWinner(newBoard);
        });
    };

    return (
        <div className="board__wrapper">
            {Object.keys(currentBoard).map((key) => (
                <div className="board__tile" key={key}>
                    <button className="board__tile-button" onClick={() => handleSquareClick(key)}>
                        {currentBoard[key] && <i className={`icon-${currentBoard[key]}`}></i>}
                    </button>
                </div>
            ))}
        </div>
    );
};

export default BoardGrid;
