import { useEffect } from "react";

const usePageColor = (colorVar) => {
    useEffect(() => {
        document.body.style.backgroundColor = `var(${colorVar})`;

        const metaThemeColor = document.querySelector("meta[name='theme-color']");
        metaThemeColor.setAttribute("content", getComputedStyle(document.documentElement).getPropertyValue(colorVar));

        return () => {
            document.body.style.backgroundColor = "";
            if (metaThemeColor) {
                metaThemeColor.setAttribute("content", "");
            }
        };
    }, [colorVar]);
};

export default usePageColor;
